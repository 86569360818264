import {defineStore} from 'pinia';

interface CartItem {
    productStyleId: number;
    quantity: number;
}

interface Coupon {
    code: string;
    selected: boolean;
    valid?: boolean;
    discount?: number;
    freeDelivery?: boolean;
}

interface CartState {
    items: CartItem[];
    coupons: Coupon[];
    token: string;
}

export const useCartStore = defineStore({
    id: 'cart',
    state: (): CartState => ({
        items: process.client ? JSON.parse(localStorage.getItem('cart') || '[]') : [],
        coupons: process.client ? JSON.parse(localStorage.getItem('coupons') || '[]') : [],
        token: process.client ? localStorage.getItem('tokenCart') || '' : '',
    }),
    actions: {
        setToken(token: string) {
            this.token = token;
            if (process.client) {
                localStorage.setItem('tokenCart', this.token);
            }
        },
        clearCart() {
            this.items = [];
            this.saveToLocalStorage();
        },
        addToCart(productStyleId: number) {
            const existingItem = this.items.find(
                (item) => item.productStyleId === productStyleId
            );

            if (existingItem) {
                existingItem.quantity += 1;
            } else {
                this.items.push({productStyleId, quantity: 1});
            }

            this.saveToLocalStorage();
        },
        updateQuantity(productStyleId: number, quantity: number) {
            const item = this.items.find(
                (item) => item.productStyleId === productStyleId
            );

            if (!item && quantity > 0) {
                this.addToCart(productStyleId)
            } else if (quantity > 0) {
                item.quantity = Math.min(quantity, 99);
            } else {
                this.removeFromCart(productStyleId)
            }

            this.saveToLocalStorage();
        },
        removeFromCart(productStyleId: number) {
            this.items = this.items.filter(
                (item) => item.productStyleId !== productStyleId
            );
            this.saveToLocalStorage();
        },
        saveToLocalStorage() {
            if (process.client) {
                localStorage.setItem('cart', JSON.stringify(this.items));
                localStorage.setItem('coupons', JSON.stringify(this.coupons));
            }
        },
        hydrateFromLocalStorage() {
            if (process.client) {
                this.items = JSON.parse(localStorage.getItem('cart') || '[]');
                this.coupons = JSON.parse(localStorage.getItem('coupons') || '[]');
                this.token = localStorage.getItem('tokenCart') || '';
            }
        },
        addCoupon(couponCode: string, autoSelect: boolean, valid?: boolean, discount?: number, freeDelivery?: boolean) {
            const existingCoupon = this.coupons.find(
                (coupon) => coupon.code === couponCode
            );

            if (autoSelect) {
                this.coupons.forEach((coupon) => {
                    coupon.selected = false;
                });
            }

            if (existingCoupon) {
                existingCoupon.valid = valid
                existingCoupon.discount = discount
                existingCoupon.freeDelivery = freeDelivery
                if (autoSelect) {
                    existingCoupon.selected = true;
                }
            } else {
                this.coupons.push({code: couponCode, selected: autoSelect, valid, discount, freeDelivery});
            }

            this.saveToLocalStorage();
        },
        removeCoupon(couponCode: string) {
            this.coupons = this.coupons.filter(
                (coupon) => coupon.code !== couponCode
            );
            this.saveToLocalStorage();
        },
        selectCoupon(couponCode: string) {
            this.coupons.forEach((coupon) => {
                coupon.selected = coupon.code === couponCode;
            });
            this.saveToLocalStorage();
        },
        resetCoupons() {
            this.coupons.forEach((coupon) => {
                coupon.valid = false
                coupon.discount = 0
            });
        },
    },
    getters: {
        getQuantity: (state) => (productStyleId: number) => {
            const item = state.items.find(item => item.productStyleId === productStyleId);
            return item?.quantity || 0;
        },
        totalItems: (state) => {
            return state.items.reduce((total, item) => total + item.quantity, 0);
        },
        formattedItemsForBackend: (state) => {
            return {
                "items": state.items.map(item => ({
                    id: item.productStyleId,
                    qty: item.quantity,
                })),
                "coupons": state.coupons.map(coupon => coupon.code),
                "selected_coupon": state.coupons.find(coupon => coupon.selected)?.code ?? null,
            }
        },
        formattedItemsForBackendCheckout: (state) => {
            return {
                "items": state.items.map(item => ({
                    id: item.productStyleId,
                    qty: item.quantity,
                })),
                "coupon": state.coupons.find(coupon => coupon.selected && coupon.valid)?.code ?? null,
            }
        },
        appliedCoupon: (state) => {
            return state.coupons.find(coupon => coupon.selected) ?? null;
        },
        hasCoupon: (state) => (couponCode: string) => {
            return state.coupons.some(coupon => coupon.code === couponCode);
        },
        totalCoupons: (state) => {
            return state.coupons.length
        },
        tokenCart: (state) => { return state.token ?? '' },
    },
});
